<template>
  <default-layout class="bg-background">
    <template #header>
      <div class="bg-primary header-element">
        <div class="p-4 pt-0 text-center">
          <div class="py-4 flex items-center justify-center">
            <span class="text-primary bg-white p-3 rounded-full">
              <svg
                class="w-11 h-11"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
          <div class="font-bold text-white">
            <div class="text-xl">
              Tạo khoản ứng thành công
            </div>
            <div v-if="isPartner" class="text-center">
              Yêu cầu của bạn đã gửi cho đối tác
              <strong>Shinhan Finance</strong>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="bg-background p-4 content-element">
      <div
        class="mb-4 p-4 pb-1 rounded-xl border border-gray-300 border-solid bg-white text-left"
      >
        <div class="mb-2 text-xl font-bold">Thông tin khoản ứng</div>
        <div class="border-b pb-2">
          <div class="flex justify-between">
            <div>Ngày ứng:</div>
            <div class="font-medium">
              {{ formatTime(new Date().toISOString()) }}
            </div>
          </div>
        </div>
        <div class="pt-2">
          <div class="flex justify-between mb-2">
            <div>Số tiền yêu cầu:</div>
            <div class="text-primary font-medium">
              {{ numberToMoney(amount) }} VNĐ
            </div>
          </div>
          <div class="flex justify-between mb-2">
            <div>Kỳ hạn:</div>
            <div class="text-primary font-medium">{{ term }} tháng</div>
          </div>
          <div class="flex justify-between mb-2">
            <div>Phí xử lý:</div>
            <div class="text-primary font-medium">
              {{ numberToMoney(paidMoney) }} VNĐ
            </div>
          </div>
          <div class="flex justify-between mb-2 border-t pt-2">
            <div>Tiền thực nhận:</div>
            <div class="text-primary font-medium">
              {{ numberToMoney(realMoney) }}
              VNĐ
            </div>
          </div>
          <div class="flex justify-between mb-2">
            <div>Tiền trả hàng tháng (dự kiến):</div>
            <div class="text-primary font-medium whitespace-nowrap">
              {{ numberToMoney(monthlyPayment) }}
              VNĐ
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 flex items-center justify-around">
        <base-button
          class="w-full bg-gray-300 normal-case font-bold text-white px-4 py-2 h-14 text-xl border-16 bg-transparent bg-primary shadow-none"
          @click="wayBackHome"
        >
          Quay lại trang chủ
        </base-button>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { numberToMoney } from '@/helpers/currency';
import constRouter from '@/constants/constRouter';
import { getApproval } from '@/helpers/localStorage';

export default defineComponent({
  components: {
    BaseButton,
    DefaultLayout,
  },
  name: 'RegisterSuccess',
  setup() {
    const store = useStore();
    const router = useRouter();
    const ekyc = store.getters.getEkyc;
    const per = ref(ekyc.per);
    const status = ref(ekyc.statusMatch);
    const toFacebook = () => {
      window.open('https://m.me/interloanp2plending', '_blank');
      // this.$router.push({ name: HOME });
    };
    const wayBackHome = () => {
      router.push({
        name: constRouter.DASHBOARD.name,
      });
    };

    const exigency = store.getters.getExigency;

    return {
      toFacebook,
      wayBackHome,
      numberToMoney,
      per,
      status,
      amount: exigency.amount,
      term: exigency.term,
      paidMoney: exigency.paidMoney,
      realMoney: exigency.realMoney,
      monthlyPayment: exigency.monthlyPayment,
    };
  },
  data: () => ({
    requestInfo: {},
  }),
  computed: {
    isPartner() {
      return getApproval() != 'true';
    },
  },
  methods: {
    formatTime(time: string) {
      const text = time.substr(0, 10); // 2021-01-18
      const array = text.split('-');
      return `${array[2]}/${array[1]}/${array[0]}`; // 18/01/2021
    },
  },
});
</script>
