
import { defineComponent, ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { numberToMoney } from '@/helpers/currency';
import constRouter from '@/constants/constRouter';
import { getApproval } from '@/helpers/localStorage';

export default defineComponent({
  components: {
    BaseButton,
    DefaultLayout,
  },
  name: 'RegisterSuccess',
  setup() {
    const store = useStore();
    const router = useRouter();
    const ekyc = store.getters.getEkyc;
    const per = ref(ekyc.per);
    const status = ref(ekyc.statusMatch);
    const toFacebook = () => {
      window.open('https://m.me/interloanp2plending', '_blank');
      // this.$router.push({ name: HOME });
    };
    const wayBackHome = () => {
      router.push({
        name: constRouter.DASHBOARD.name,
      });
    };

    const exigency = store.getters.getExigency;

    return {
      toFacebook,
      wayBackHome,
      numberToMoney,
      per,
      status,
      amount: exigency.amount,
      term: exigency.term,
      paidMoney: exigency.paidMoney,
      realMoney: exigency.realMoney,
      monthlyPayment: exigency.monthlyPayment,
    };
  },
  data: () => ({
    requestInfo: {},
  }),
  computed: {
    isPartner() {
      return getApproval() != 'true';
    },
  },
  methods: {
    formatTime(time: string) {
      const text = time.substr(0, 10); // 2021-01-18
      const array = text.split('-');
      return `${array[2]}/${array[1]}/${array[0]}`; // 18/01/2021
    },
  },
});
